import React from "react"
import SEO from "../components/SEO"

const PeoplePage = () => (
  <SEO
    title="Event"
    description="MediaLink's team of strategic advisors helps accelerate change and growth. With deep experience in media, advertising, investment, and emerging technologies."
  />
)

export default PeoplePage
